import { db } from '@/services/firebase/instance'
import { doc, getDoc, setDoc, updateDoc, onSnapshot, Unsubscribe, deleteField, query , collection, getDocs, where} from 'firebase/firestore'


const qrcodesDatabase = () => {
  const collectionName = 'qrcodes'

  const get = async (qrcodeId: string): Promise<any | null> => {
    const docRef = doc(db, collectionName, qrcodeId)
    const docSnap = await getDoc(docRef)

    if(docSnap.exists()) {
      return Object.assign({}, {
        ... docSnap.data(),
        docId: docSnap.id
      }) as any
    } else {
      return null
    }
  }


  const getPairCodeByEventId = async (eventId: string): Promise<any> => {
    const q = query(collection(db, collectionName), where('eventId', '==', eventId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {        
        return  querySnapshot.docs.map(doc => doc.data());
    } else {
        return [];
    }
  };

  const _update = async (qrcodeId: string, payload: Event) => {
    const docRef = doc(db, collectionName, qrcodeId)   
    return updateDoc(docRef, payload as any)
  }


  const _set = async (qrcodeId: string, payload: Event) => {
    const docRef = doc(db, collectionName, qrcodeId)
    return setDoc(docRef, payload as any, { merge: true })
  }
  

  return {
    get,   
    _update,  
    _set,  
    getPairCodeByEventId
  }
}

export default qrcodesDatabase